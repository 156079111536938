header {
  // add a shadow to the header
  box-shadow: 0 0 1rem rgba(0,0,0,0.2);
  z-index: 1021; // for box-shadow to appear over navbar

  // banner colours
  background-image: linear-gradient(to right, $primary, $white 80%);
  color: color-contrast($light);
}

.banner-tag {
  &:hover, &:focus {
    // banner-link color changes when hover/focus on parent as well
    .banner-link {
      color: color-contrast($light) !important;
    }
  }
}

// new class for extra-bold text
.extra-bold {
  font-weight: 1000;
}

