:root {
  // global variable for the height of the main menu nav
  --nav-height: 56px;
}

.skip-link {
  position: absolute;
  top: -50px;
  left: 0;
  background-color: $primary;
  color: white;
  padding: 10px;
  z-index: 100;

  &:focus {
    top: 0;
  }
}

.sidebar {
  position: sticky; // keep the sidebar fixed while scrolling
  align-self: flex-start; // for sticky to work within flexbox
  top: var(--nav-height); // keep the sidebar fixed below the nav
  height: calc(100vh - var(--nav-height)); // fill the remaining height
  overflow-y: scroll; // allow scrolling within the sidebar
}

section {
  // offset for on-page anchor links
  scroll-margin-top: var(--nav-height);
}

// new class for cta buttons
.btn-cta {
  @include button-variant(
    $ctaBackgroundColor,
    $ctaBackgroundColor,
  );
}

// modify bootstrap card styles to use primary color
.card-header {
  background-color: $primary;
  color: white;
}
.card-body {
  background-color: lighten($primary, 40%);
}
a:hover .card-body {
  background-color: lighten($primary, 30%);
}

// except cta cards
.card-cta .card-header {
  background-color: $ctaBackgroundColor;
}
.card-cta .card-body {
  background-color: lighten($ctaBackgroundColor, 40%);
}
// a:hover .card-cta .card-body {
//   background-color: lighten($ctaBackgroundColor, 30%);
// }

// honeypot field is the div parent of the input field with id "subject"
.subject {
  height: 0;
  overflow: hidden;
  margin-bottom: 0 !important;
}
